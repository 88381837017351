





















































































import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"

@Component({
	components: {
		topNav
	}
})
export default class Default extends Vue {
	goodsData: any = ""
	isAddress: boolean = true
	consumePoints: any = ""
	showBalloons: boolean = true
	isModelShow: boolean = false
	isLoading: boolean = false
  esbPriceAll: any = 0
  receiverInfo:any={}
  expressFee:any=0
  isExpressFee:any=false
  isFar:any=false

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}
  @Watch("receiverInfo",{deep:true})
  handleWatchFee() {
    this.judgeFee()
  }

	created() {
		if (
			sessionStorage.getItem("orderKey") &&
			sessionStorage.getItem("orderKey") == JSON.stringify(this.$route.query.key)
		) {
			this.$router.push("/")
		}

		const goodsDetailsData: any = this.$route.query.goodsDetailsData
		this.goodsData = JSON.parse(decodeURIComponent(goodsDetailsData))
		this.lastestAddress()
		const numString: any = this.$route.query.goodsNum
		this.consumePoints = Number(numString) * this.goodsData.salePoints // 总积分
    // 处理积分+金额,实物,卡券
    this.judgeFee()
	}

	mounted() {}

	lastestAddress() {
    this.$api.receiverSelect().then((res:any)=>{
      if (res.data == null) {
        this.isAddress = false
      } else {
        this.isAddress = true
        this.receiverInfo=res.data
      }
    })
	}
  judgeFee() {
    if(this.goodsData.priceShowStyle != 3) return
    this.esbPriceAll = Number(this.$route.query.goodsNum) * this.goodsData.esbSalePrice // 商品金额
    if(this.goodsData.category!='实物') return
    if(!this.receiverInfo) return
    this.isFar=this.goodsData.postageDesc?this.goodsData.postageDesc.includes(this.receiverInfo.province):false;
    console.log(this.isFar,'this.isFar');
    if(this.isFar) {
      this.isExpressFee=false;
      this.expressFee=this.goodsData.farPostagePrice; // 是偏远地区
    } else if(this.goodsData.isPostage) {
      this.isExpressFee=true;
      this.expressFee=0; // true,是包邮
    } else {
      this.isExpressFee=false;
      this.expressFee=this.goodsData.postagePrice;
    }
    this.esbPriceAll=this.esbPriceAll+this.expressFee
    this.esbPriceAll=Number(this.esbPriceAll).toFixed(2)
  }

	toEditAdress() {
    this.$router.push({
      path:"/my/addressList",
      query:{
        origin:'order'
      }
    })
	}

	submitOrder() {
		if (this.isAddress == false) {
      this.showToast('请填写完整信息')
		} else {
			this.isModelShow = true
		}
	}
  async submitOrderPromise() {
    this.isModelShow = false
		this.isLoading = true
    let submitObj:any={}
    let orderObj:any={}
    if(this.goodsData.priceShowStyle==3) {
      submitObj={
        totalPoints:this.consumePoints, // 总积分
        totalPrices:this.esbPriceAll,
        fuaOrderItems:[],
        antiDuplicationToken: this.$route.query.key
      }
      let dgObj={
        goodsId:this.goodsData.id,
        goodsName:this.goodsData.name,
        goodsNumber: this.$route.query.goodsNum,
        consumePoints: this.goodsData.salePoints,
        esbSalePrice: this.goodsData.esbSalePrice,
        postagePrice: this.expressFee, // 快递费
        couponShowPrice: this.goodsData.couponShowPrice, //面值
        isPostage: this.goodsData.isPostage, // 是否包邮
        isFarDistrict: this.isFar, // 是否是远距离
        supplier: this.goodsData.supplier,
        category: this.goodsData.category,
        fuaGoodSerialNumber: this.goodsData.fuaGoodSerialNumber
      }
      submitObj={...submitObj,...this.receiverInfo};
      submitObj.fuaOrderItems.push(dgObj);
      orderObj=await this.fuaorderSubmitApi(submitObj);
      this.isLoading = false
      if(orderObj.code==200) {
        this.goAppPay(orderObj.data)
        return
      }
    } else {
      submitObj={
        goodsId: this.goodsData.id,
        goodsName: this.goodsData.goodsName,
        goodsNumber: this.$route.query.goodsNum,
        consumePoints: this.consumePoints, // 总积分
        antiDuplicationToken: this.$route.query.key
      }
      submitObj={...submitObj,...this.receiverInfo};
      orderObj=await this.submitOrderApi(submitObj);
      this.isLoading = false
      if(orderObj.code==200) {
        this.showToast('兑换成功')
        sessionStorage.setItem("isOrder", "true")
				sessionStorage.setItem("orderKey", JSON.stringify(this.$route.query.key))
				// this.$router.push(`/goodsDetails/${this.goodsData.id}?priceShowStyle=${this.goodsData.priceShowStyle}`)
				this.$router.push(`/my/orderRecord?id=${this.goodsData.id}&&priceShowStyle=${this.goodsData.priceShowStyle}`)
				sessionStorage.setItem("isOrder", "false")
        return
      }
    }
    if(orderObj.msg.indexOf('积分不足') != -1) {
      let data: any = [true]
      this.VueFunc.$emit("integralLack",data)
      sessionStorage.setItem("orderKey", JSON.stringify(this.$route.query.key))
			this.$router.push(`/goodsDetails/${this.goodsData.id}?priceShowStyle=${this.goodsData.priceShowStyle}`)
      sessionStorage.setItem("isOrder", "false")
    } else {
      this.isLoading = false
      this.showToast(orderObj.msg)
      sessionStorage.setItem("orderKey", JSON.stringify(this.$route.query.key))
			this.$router.push(`/goodsDetails/${this.goodsData.id}?priceShowStyle=${this.goodsData.priceShowStyle}`)
      sessionStorage.setItem("isOrder", "false")
    }
  }
  submitOrderApi(data:any) {
    return new Promise((resolve)=>{
      this.$api.submitOrder(data).then((res: any) => {
        resolve(res)
      })
    })
  }
  fuaorderSubmitApi(data:any) {
    return new Promise((resolve)=>{
      this.$api.fuaorderSubmit(data).then((res: any) => {
        resolve(res)
      })
    })
  }
  showToast(val:any) {
    this.showBalloons = true
    let data: any = [val, this.showBalloons]
    this.VueFunc.$emit("balloons", data)
    setTimeout(() => {
      this.showBalloons = false
      data = [val, this.showBalloons]
      this.VueFunc.$emit("balloons", data)
    }, 2000)
  }
  goAppPay(orderId:any) {
    const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
		if (u.indexOf("eshebao") != -1) {
			if (isAndroid) {
				(window as any).eshebao.gotoPayOrder(orderId)
			} else if (isiOS) {
				(window as any).webkit.messageHandlers.gotoPayOrder.postMessage(orderId)
			}
		}
  }
}
